(function($) {

    "use strict";
    $(document).on('ready', function() {
        datepickerPL(); 
        $('.status_blood').click(function(){
            $('.status_platelets').removeClass('enable');
            $(this).addClass('enable');

            $('.container.platelets').fadeOut();
            $('.container.blood').fadeIn();
            
            $('.date__platelets').fadeOut();
            $('.date__blood').fadeIn();
        })
        $('.status_platelets').click(function(){
            $('.status_blood').removeClass('enable');
            $(this).addClass('enable');
            
            $('.status_platelets').addClass('enable');
            $('.container.blood').fadeOut();
            $('.container.platelets').fadeIn();
            
            $('.date__blood').fadeOut();
            $('.date__platelets').fadeIn();
        })
        
        $('.menu-toggle').click(function () {
            $(this).toggleClass('active');
            $('.nav__main').slideToggle();
        })
        $('h1').textfill({
            maxFontPixels: 36,
            minFontPixels: 12,
        })
        $('.owl-carousel').owlCarousel({
            loop:false,
            margin:50,
            responsiveClass:true,
            nav:false,
            autoWidth:true,
            autoplay:true,
            autoplayTimeout:2500,
            autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1,
                    center:true,
                    loop:true
                },
                600:{
                    items:2,
                    center:true,
                    loop:true
                },
                1000:{
                    items:3,
                    nav:true
                }
            }
        });
        var $affectedElements = $("p, div, a"); // Can be extended, ex. $("div, p, span.someClass")

        // Storing the original size in a data attribute so size can be reset
        $affectedElements.each( function(){
            var $this = $(this);
            $this.data("orig-size", $this.css("font-size") );
        });

        $(".adjust-box .increase").click(function(){
            changeFontSize(1);
        })

        $(".adjust-box .decrease").click(function(){
            changeFontSize(-1);
        })

        $("#btn-orig").click(function(){
            $affectedElements.each( function(){
                var $this = $(this);
                $this.css( "font-size" , $this.data("orig-size") );
            });
        })

        function changeFontSize(direction){
            $affectedElements.each( function(){
                var $this = $(this);
                $this.css( "font-size" , parseInt($this.css("font-size"))+direction );
            });
        }
        
        
        $('.icon-adjust').click(function(){            
            $('body').toggleClass('adjust');
            if ($('body').hasClass('adjust')){
                setCookie('adjust',1,10);
            }
            else{
                eraseCookie('adjust');
            }
        })
        function setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }
        function eraseCookie(name) {   
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        $('.gallery div a').attr('data-lightbox', 'gallery');
        $('img[class*="wp-image"]').parent().attr('data-lightbox', 'gallery');
    });
    let timer;
    let delay = 300;
    $(document).on('mouseenter mouseleave click', '.nav__main > ul > li', function (event) {
        if (event.type == 'mouseenter' || event.type == 'click') {

            let currentElement = $(this);
            $('.nav__main > ul > li').not(currentElement).removeClass('show-submenu');
            currentElement.addClass("show-submenu");
            clearTimeout(timer);

        } else {
            timer = setTimeout(function () {
                $('.nav__main > ul > li').removeClass("show-submenu");
            }, delay);
        }
    });
    function datepickerPL(){
        if ($("#ui-datepicker-div").length) {
            $.datepicker.regional['pl'] = {
                closeText: "Zamknij",
                prevText: "&#x3C;Poprzedni",
                nextText: "Następny&#x3E;",
                currentText: "Dziś",
                monthNames: [ "Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec","Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień" ],
                monthNamesShort: [ "Sty","Lu","Mar","Kw","Maj","Cze","Lip","Sie","Wrz","Pa","Lis","Gru" ],
                dayNames: [ "Niedziela","Poniedziałek","Wtorek","Środa","Czwartek","Piątek","Sobota" ],
                dayNamesShort: [ "Nie","Pn","Wt","Śr","Czw","Pt","So" ],
                dayNamesMin: [ "N","Pn","Wt","Śr","Cz","Pt","So" ],
                weekHeader: "Tydz",
                dateFormat: "dd.mm.yy",
                firstDay: 1,
                isRTL: false,
                showMonthAfterYear: false,
                yearSuffix: "" };
            $.datepicker.setDefaults($.datepicker.regional['pl']);
        }
    }
})(window.jQuery);